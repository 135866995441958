/* eslint no-undef: 0 */
import React, {Component} from "react"
import s from "./style.module.scss"
import { Helmet } from "react-helmet"
import Layout from "../../components/Layout"
import { isAuthenticated, customLogin } from "../../utils/auth"
import { navigate } from "gatsby"

import { Form, Field } from "../../components/Form"
import Lang, { t } from "../../components/Lang"

class Login extends Component {

    async componentDidMount() {
        if (isAuthenticated()) {
            navigate("/campaigns/247256867411067410")
        }
    }

    createAccount = async (values) => {
        const { email, password } = values
        console.log("EMAIL", email)
        await customLogin(email, password)
    }

    render() {

        return <Layout>
            <Helmet>
				<title>Invite - My Private Fund</title>
			</Helmet>
            <header>
                <div className={s.background}></div>
            </header>
            <main className={s.center}>
                <div className={s.container}>

                    <h1><Lang en="Login" fr="Connexion" /></h1>

                    <Form
                        submit={<Lang en="Login" fr="Connexion" />}
                        error={t({ en: "The username or password is invalid.", fr: "Le nom d'utilisateur ou mot de passe est invalide." })}
                        onSubmit={this.createAccount} >
                            <Field type="email" name="email" label={<Lang en="Email" fr="Courriel" />} required />
                            <Field type="password" name="password" label={<Lang en="Password" fr="Mot de passe" />} required />
                    </Form>

                </div>
            </main>
        </Layout>
    }
}


export default Login